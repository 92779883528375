<template>
  <div class="Professor">
    <ContainerCard :locationMsg="locationMsg">
      <!-- 搜索 -->
      <!-- <Search
        v-if="searchMsg.type.length > 0"
        :searchMsg="searchMsg"
        @searchKey="searchHandle"
      ></Search> -->
      <div class="search">
        <div class="title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="title-size">双师资源库</span>
        </div>
        <div class="search-box">
          <span><img src="../../assets/images/政策公告-搜索.png" class="search-icon"/></span>
          <input class="input" v-model="input" placeholder="请输入您要搜索的内容" />
          <div class="search-button" @click="searchHandle">
            <span class="button-word">搜索</span>
          </div>
        </div>
      </div>

      <!-- <Technology style="width: 1260px" @techHandle="techHandle"></Technology> -->
      
      <!-- 成果库列表 -->
      <div class="achievement-container-wrapper" v-empty="List">
        <div
          class="achievement-container"
          v-for="(pro, index) in List"
          :key="index"
          @click="toDetail(pro.billid)"
        >
          <div class="achidevments">
            <el-image class="img" :src="httpConfig.baseURL+pro.photo" v-if="pro.photo" alt="" />
            <div v-if="!pro.photo" class="no-imgbox">
              <el-image class="no-img" :src=" defaultImg" alt="" />
            </div>
            <div class="achidevments-info">
              <div class="text1">
                <span class="name">{{ pro.name }}</span>
                <!-- <span
                  class="techList"
                  v-for="(item, index) in pro.techList"
                  :key="index"
                  >{{ item }}</span
                > -->
              </div>
              
              <div class="text2">
                <span class="label">出生年月</span>
                <span class="value">{{ pro.birthday }}</span>   <!-- {{ pro.post }} -->
              </div>
              <div class="text2">
                <span class="label">单位</span>
                <span class="value">{{ pro.company }}</span>  <!-- {{ pro.name }} -->  
              </div>
            </div>
            <!-- <div class="top-achi"> -->
            <!-- img -->

            <!-- word/logo -->
            <!-- <div class="word-container">
                <span class="word">{{ pro.name }}</span>
                <div class="log-container">
                  <span
                    class="logo"
                    v-for="(item, index) in pro.techList"
                    :key="index"
                    >{{ item }}</span
                  >
                </div>
                <div class="profession">职位：{{ pro.post }}</div>
              </div> -->
            <!-- </div> -->
            <!-- line -->
            <!-- <div class="line"></div> -->
            <!-- miniword -->
            <!-- <div class="miniword-container">
              <span class="mini-word">{{ pro.company }}</span>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <Pagination
        class="center"
        @changeIndex="setCurrentPage"
        :total="total"
        :totalPage="totalPage"
        :pageSize="params.pageSize"
      ></Pagination>
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Search from "../../components/SixLibraries/Search.vue";
import Pagination from "../../components/Pagination.vue";
// import { getProfessorInfo } from "../../api/SixLibraries/Professor/Professor";
import { getTeachersInfo } from "../../api/SixLibraries/Teachers/Teachers";
import ContainerCard from "../../components/newContainerCard.vue";
import Technology from "../../components/Info/Technology.vue";
import { mapGetters } from "vuex";
import defaultImg from "../../assets/images/用户默认.png";
import httpConfig from "../../common/utils/config";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },

  data() {
    return {
      httpConfig,
      defaultImg,
      searchMsg: {
        title: "技术领域",
        type: [],
      },
      input: '',
      locationMsg: [],
      List: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      params: {
        tech_field: "", //技术领域
        keyword: "",
        orderstr: "make_date", //click_num
        pageNo: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    let techList = [];
    techList.push({
      label: "全部",
      value: "all",
    });

    Object.keys(this.techfieldList).forEach((item) => {
      techList.push({
        label: item,
        value: this.techfieldList[item],
      });
    });
    this.searchMsg.type = techList;
  },
  methods: {
    async getData() {
      let that = this;
      that.fullscreenLoading = true;
      let res = await getTeachersInfo(that.params);
      console.log(res);
      that.List = res.data.items.map((item) => {
        let tmp = item;
        tmp.techList = [];
        Object.keys(that.techfieldList).forEach(function (key) {
          if (
            tmp?.tech_field &&
            tmp?.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
          ) {
            tmp.techList.push(key);
          }
        });
        return tmp;
      });
      console.log("双师库List的数据是:",this.List)
      this.total = res.data.totalCount;
      // this.totalPage = res.data.totalPage
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
    },
    searchHandle(val) {
      this.params.keyword = this.input;
      this.getData();
    },
    techHandle(val) {
      this.params.keyword = this.input;
      this.params.tech_field = val === "all" ? "" : val;
      this.getData();
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    toDetail(id) {
      this.$router.push({
        path: "/Teachers/TeachersDetails",
        query: { id: id },
      });
    },
  },
  mounted() {
    this.getData();
  },
  components: { Location, Search, Pagination, ContainerCard, Technology },
};
</script>
<style scoped lang="scss">
.center {
  justify-content: center;
  // margin: 3rem;
  width: 100%;
  height: 78px;
}
// #region
// .professors {
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
// }
// .professorItem {
//   display: flex;
//   /* flex-wrap: wrap; */
//   justify-content: start;
//   width: 45%;
//   /* box-sizing: border-box; */
//   border: 1px solid #f2f2f2;
//   cursor: pointer;
//   margin-bottom: 20px;
//   padding: 20px;
// }
// .photo {
//   width: 20%;
//   min-width: 120px;
//   margin-right: 20px;
// }
// .photo > img {
//   width: 100%;
// }
// .professorInfo > p {
//   margin: 0 0 12px 0;
//   font-size: 13px;
// }
// .professorInfo > p:nth-of-type(1) {
//   font-size: 18px;
//   font-weight: bold;
//   margin-bottom: 20px;
// }
// .professorInfo > .el-button {
//   margin: 10px 10px 10px 0;
// }
// .block {
//   text-align: center;
//   margin: 30px 0;
// }
// #endregion

// 列表容器样式
@mixin setHeightAndWidth($w, $h) {
  width: $w;
  height: $h;
}
@mixin setFont($size, $lih, $col) {
  font-size: $size;
  font-family: 思源黑体 CN Medium;
  line-height: $lih;
  color: $col;
}
.search {
  height: 86px;
  width: 1260px;
  box-sizing: border-box;
  padding: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    img {
      @include setHeightAndWidth(8px, 10px);
      margin-right: 8px;
    }
    .title-size{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
    }
    .word {
    }
  }
  .search-box {
    box-sizing: border-box;
    width: 696px;
    height: 38px;
    padding: 12px 0px 12px 12px;
    display: flex;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #2885ff;
    align-items: center;
    margin-bottom: 12px;
    .input {
      // height: 34px;
      width: 572px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      width: 100%;
      &::-moz-placeholder {
        font-size: 14px;
        color: #999999;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      &:active {
        transform: scale(0.96);
      }
    }
    > span .search-icon {
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
    .search-button {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1px;
      border-radius: 0px 6px 6px 0px;
      width: 72px;
      height: 38px;
      background-color: #2885ff;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      span {
        font-size: 16px;
      }
    }
  }
}
.achievement-container-wrapper {
  width: 1260px;
  // height: 456px;
  box-sizing: border-box;
  // max-height: 460px;
  display: grid;
  grid-template-columns: 1fr 1fr; /// 三列三等分
  grid-gap: 0px 0px; // 行间距，列间距
  .achievement-container {
    // width: 100%;
    // height: 100%;
    width: 630px;
    box-sizing: border-box;
    height: 152px;
    // box-shadow: 0px 0px 3px 2px rgba(228, 228, 228, 0.5);
    border: 1px solid #f1f1f1;
    padding: 30px 30px 30px 0px;
    cursor: pointer;
    &:nth-child(odd) {
      border-right: 0px solid #f1f1f1;
      padding-left: 30px;
    }
    &:nth-child(even) {
      border-left: 0px solid #f1f1f1;
    }
    cursor: pointer;
    .achidevments {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .img {
        width: 72px;
        height: 92px;
        margin-right: 10px;
        border-radius: 6px;
      }
      .no-imgbox {
        width: 72px;
        height: 92px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 92px;
        .no-img{
          width: 20px;
          height: 20px;
        }
      }
      .achidevments-info {
        height: 92px;
        // width: 100%;
        box-sizing: border-box;
        padding: 4px 20px 9px 20px;
        .text1 {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .name {
            @include setFont(18px, 27px, #101010);
            font-weight: 600;
            margin-right: 12px;
          }

          .techList {
            @include setFont(12px, 18px, #ffffff);
            padding: 3px 5px 0px 5px;
            box-sizing: border-box;
            background-color: #2885ff;
            border-radius: 3px;
          }
        }
        .text2 {
          margin-top: 8px;
          margin-bottom: 10px;
          .label {
            @include setFont(12px, 18px, #8896a7);
            margin-right: 20px;
            margin-bottom: 12px;
          }
          .value {
            @include setFont(12px, 18px, #404040);
          }

          // &:nth-child(1) {
          //   margin-bottom: 12px;
          // }
        }
        .text2:nth-child(3) {
          .label{
            margin-right: 47px;
          }
        }
      }
      .top-achi {
        display: flex;
        width: 100%;
        height: 100%;

        .word-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .word {
            margin-top: 28px;
            font-size: 28px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: bold;
            color: #101010;
          }

          .log-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 10px;

            .logo {
              padding: 4px 10px;
              border-radius: 8px;
              border: 1px solid #3e9cff;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #3e9cff;
              line-height: 21px;
              margin: 3px 3px 3px 0;
            }
          }

          .profession {
            margin-top: 16px;
          }
        }
      }

      .line {
        width: 100%;
        height: 1px;
      }

      .miniword-container {
        align-self: flex-start;
        margin-top: 16px;
        .mini-word {
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
          overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
}
</style>
